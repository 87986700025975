<template>
  <div>
    <h1 class="text-h4">Управление товарами</h1>
    <v-divider class="my-2"></v-divider>
    <v-form v-model="form.valid" :disabled="busy" @submit.prevent="submitForm">
      <v-card>
        <v-card-title>Редактирование товара {{ itemCode }}</v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <v-switch
                v-model="form.data.isActive"
                dense
                label="Включено"
                hint="Если включено, то товар сразу становится доступным"
                persistent-hint
                inset
                class="mb-2"
              ></v-switch>
            </v-col>
            <v-col>
              <v-switch
                v-model="form.data.isPublic"
                dense
                label="Опубликовано"
                hint="Если включено, то товар доступен на сайте"
                persistent-hint
                inset
                class="mb-2"
              ></v-switch>
            </v-col>
            <v-col>
              <v-switch
                v-model="form.data.isInStock"
                dense
                label="Наличие"
                hint="Если включено, то товар в наличии и может быть заказан"
                persistent-hint
                inset
                class="mb-2"
              ></v-switch>
            </v-col>
          </v-row>
          <v-text-field
            v-model="form.data.name"
            label="Название"
            counter="150"
            :rules="form.rule.name"
          />
          <v-textarea
            v-model="form.data.content"
            label="Состав"
            counter="1024"
          ></v-textarea>
          <v-row>
            <v-col>
              <v-text-field
                v-model="form.data.price"
                label="Цена"
                counter="12"
                :rules="form.rule.price"
                :append-icon="form.data.priceOld>0 ? 'mdi-restore': 'mdi-sale-outline'"
                @click:append="restorePrice"
              />
            </v-col>
            <v-col>
              <v-text-field
                v-model="form.data.priceOld"
                label="Старая цена"
                hint="Укажите старую цену чтобы на сайте показывалось как снижение цены"
                persistent-hint
                counter="12"
                :rules="form.rule.priceOld"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                v-model="form.data.length"
                label="Длина"
                counter="12"
                :rules="form.rule.length"
                :append-icon="lockLengthWidth ? 'mdi-lock-outline': 'mdi-lock-open-variant-outline'"
                @click:append="toggleLockLengthWidth"
              />
            </v-col>
            <v-col>
              <v-text-field
                v-model="form.data.width"
                label="Ширина"
                counter="12"
                :rules="form.rule.width"
                :append-icon="lockLengthWidth ? 'mdi-lock-outline': 'mdi-lock-open-variant-outline'"
                :disabled="lockLengthWidth"
                @click:append="toggleLockLengthWidth"
              />
            </v-col>
            <v-col>
              <v-text-field
                v-model="form.data.height"
                label="Высота"
                counter="12"
                :rules="form.rule.height"
              />
            </v-col>
          </v-row>
          <v-textarea
            v-model="form.data.description"
            label="Описание"
            counter="1024"
          ></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="$router.back()">Назад</v-btn>
          <!--          <v-btn :to="{name: 'item.view', params: { id }}">Отмена</v-btn>-->
          <v-btn color="primary" :disabled="!form.valid" type="submit">Отправить</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </div>
</template>

<script>
import { dashboardPageCommonAttributes } from '@/mixins/DashboardPageCommonAttributes'
import { ruleNumeric, ruleRequired } from '@/utils/FormRules'
import { mapActions, mapState } from 'vuex'
import ItemUpdateForm from '@/model/item/ItemUpdateForm'

export default {
  ...dashboardPageCommonAttributes,
  name: 'ItemEditPage',
  props: {
    id: {
      type: [String],
      required: true
    }
  },
  data() {
    return {
      lockLengthWidth: false,
      form: {
        valid: true,
        data: {
          name: null,
          description: null,
          content: null,
          length: 0,
          width: 0,
          height: 0,
          price: 0,
          priceOld: 0,
          isActive: false,
          isInStock: false,
          isPublic: false
        },
        rule: {
          name: [ruleRequired()],
          length: [ruleNumeric()],
          width: [ruleNumeric()],
          height: [ruleNumeric()],
          price: [ruleNumeric()]
        }
      }
    }
  },
  computed: {
    props() {
      return props
    },
    ...mapState('item_edit', ['item', 'busy']),
    itemCode() {
      if (!this.item) {
        return null
      }

      if (this.item.codeBs) {
        return this.item.code + ' (' + this.item.codeBs + ')'
      }

      return this.item.code
    }
  },
  watch:{
    'form.data.length'(newValue) {
      if (this.lockLengthWidth) {
        this.form.data.width = newValue
      }
    }
  },
  created() {
    this.fetchData({ id: this.id })
      .then(() => {
        this.form.data.name = this.item.name
        this.form.data.description = this.item.description
        this.form.data.content = this.item.content
        this.form.data.length = this.item.length
        this.form.data.width = this.item.width
        this.form.data.height = this.item.height
        this.form.data.price = this.item.price
        this.form.data.priceOld = this.item.priceOld
        this.form.data.isActive = this.item.isActive
        this.form.data.isInStock = this.item.isInStock
        this.form.data.isPublic = this.item.isPublic

        this.lockLengthWidth = this.form.data.length === this.form.data.width
      })
  },
  methods: {
    ...mapActions('item_edit', ['fetchData', 'updateItem']),
    restorePrice() {
      if (this.form.data.priceOld > 0) {
        this.form.data.price = this.form.data.priceOld
        this.form.data.priceOld = 0
      } else {
        this.form.data.priceOld = this.form.data.price
      }
    },
    toggleLockLengthWidth() {
      this.lockLengthWidth = !this.lockLengthWidth
      if (this.lockLengthWidth) {
        this.form.data.width = this.form.data.length
      }
    },
    submitForm() {
      const form = ItemUpdateForm.create({ ...this.item, ...this.form.data })

      this.updateItem({ form })
        .then(() => this.$router.back())
        // .then(() => this.$router.push({ name: 'item.view', params: { id: this.id } }))
    }
  }
}
</script>

<style scoped>

</style>
